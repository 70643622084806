import React from 'react';
import { ImageField, Field, LinkField, Link, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { StyleguideComponentProps } from 'lib/component-props';
import Image from 'components/atoms/Image';
import { ItemValue } from 'components/types/ListItem';
import Modal from 'assets/shared/Modal';

type ContentListingProps = StyleguideComponentProps & {
	fields: {
		BaseImage: ImageField;
		BaseImageRight: {
			value: boolean;
		};
		BaseHeading: Field<string>;
		BaseHeadingH3: {
			value: boolean;
		};
		BaseDescription: Field<string>;
		BaseBackground: {
			value: boolean;
		};
		BaseLink: LinkField;
		BaseLinkType: ItemValue;
		BaseVideoUrl: Field<string>;
	};
};

const ContentListing = (props: ContentListingProps): JSX.Element => {
	if (props.fields == undefined || !props.fields.BaseHeading || !props.fields.BaseDescription) {
		return <div>Data source is not defined</div>;
	}

	const {
		BaseImage,
		BaseImageRight,
		BaseHeading,
		BaseHeadingH3,
		BaseDescription,
		BaseBackground,
		BaseLink,
		BaseLinkType,
		BaseVideoUrl,
	} = props?.fields;

	return (
		<div className="overhauled">
			<div className="content-listing">
				<div
					className={`bg-wrapper ${BaseImageRight?.value == true ? 'img-right' : ''} ${
						BaseBackground?.value == true
							? 'bg-secondary inner-vertical-spacing'
							: 'outer-vertical-spacing'
					}`}
				>
					<div className="jss-inner-container">
						<div className="inner-content">
							{BaseImage?.value?.src && (
								<div className="listing-img">
									<Image
										imageUrl={BaseImage?.value?.src as string}
										alt={
											(BaseImage?.value?.alt as string) != ''
												? (BaseImage?.value?.alt as string)
												: 'Image'
										}
									/>
									{BaseVideoUrl?.value && (
										<>
											<div id="modal-root"></div>
											<Modal
												portalId="modal-root"
												openBtnIcon="play-filled"
												openBtnLabel=""
												customClass="video-modal"
											>
												<iframe
													src={BaseVideoUrl?.value}
													title="YouTube video player"
													allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
												></iframe>
											</Modal>
										</>
									)}
								</div>
							)}
							<div
								className={`listing-content ${
									BaseImage?.value?.src != null || undefined ? 'with-img' : ''
								}`}
							>
								{BaseHeading && BaseHeadingH3?.value == true ? (
									<h3 className="listing-heading osf-inner-h3">{BaseHeading?.value}</h3>
								) : (
									<h2 className="listing-heading">{BaseHeading?.value}</h2>
								)}
								<RichText tag="" className="listing-description osf-body" field={BaseDescription} />
								{BaseLink && BaseLinkType?.name && (
									<Link className={`btn-${BaseLinkType?.name.toLowerCase()}`} field={BaseLink} />
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContentListing;
